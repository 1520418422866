import React from 'react'
import { Link } from 'gatsby'
import Layout from 'components/Layout'

const NotFound = () => (
  <Layout>
    <oma-grid-row className='row'>
      <section className='four-o-four'>
        <div className='heimr-section'>
          <h2 className='heimr-section__heading'>Tyvärr, sidan finns inte!</h2>
          <br />
          <p>
            Gå tillbaka till{' '}
            <Link to='/' className='link__with-underline'>
              startsidan.
            </Link>
          </p>
        </div>
      </section>
    </oma-grid-row>
  </Layout>
)

export default NotFound
